import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import MapLocation from 'component/common/Map/MapLocation'
import { getPacakge } from 'utils/packageHelper'
import { updatePackage } from 'features/packages/packagesAction'
import { packageStatusEnum } from 'constants/packageStatus'

const isValidCoordinate = (coord) => !isNaN(parseFloat(coord)) && isFinite(coord);

export const UpdatePackageLocation = ({ id }) => {
    const dispatch = useDispatch();

    const { packages } = useSelector(
        (state) => state?.packages
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const parcel = getPacakge(packages, id)
    const userDC = user?.distributionCenterResponseVm
    const allowEdit = parcel?.packageStatus !== packageStatusEnum?.PROCESSED_STREET_PERFECT_MARKED_BAD
    const [position, setPosition] = useState({
        lat: isValidCoordinate(parcel?.lat) ? parseFloat(parcel?.lat) : userDC?.dcLat,
        lon: isValidCoordinate(parcel?.lon) ? parseFloat(parcel?.lon) : userDC?.dcLon,
    });

    const handleMarkerDrop = ({ lat, lng }) => {
        if (isValidCoordinate(lat) && isValidCoordinate(lng)) {
            setPosition({ lat: parseFloat(lat), lon: parseFloat(lng) });
            // To Do - Call api from here
            const params = {
                ...parcel,
                lat,
                lon: lng
            }
            dispatch(updatePackage(params))
        }
    };

    console.log('==================',parcel)

    return (
        <MapLocation onMarkerDragEnd={handleMarkerDrop}
            initialLat={position?.lat}
            initialLng={position?.lon} 
            allowEdit={allowEdit}/>
    )
}