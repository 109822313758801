import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import DataTable from 'component/dataTable/dataTable'
import { Link } from '@mui/material';
import { iconStyle } from 'utils/common'
import { Audit } from 'component/audit/audit'
import { SpecialInstrucionsCellRenderer } from 'component/packages/SpecialInstructionsCellRenderer'
import { urls } from 'utils/urls'
import { getRouteParcels } from 'utils/routeHelper'
import { getDeliveryStatusValue, canViewInternalComments } from 'utils/packageHelper'
import { DeliveryStatusCellRenderer } from 'component/packages/deliveryStatusCellRenderer'

const rowKey = "id"

export const RouteParcels = ({ routeDetail, height }) => {
    const { user } = useSelector(
        (state) => state?.auth
    )
    const navigate = useNavigate();

    const data = getRouteParcels(routeDetail?.routeStops, user)

    const trackPackagestatus = (id) => {
        navigate(`${urls?.PARCEL_TRACKING_WIDGET_URL}?tracker-id=${id}`)
    }
    const hasInternalCommentsAccess = canViewInternalComments(user)

    const columns = [
        {
            accessor: "routeStopSequence",
            title: "Stop Sequence No",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.routeStopSequence || '--'
        },
        {
            accessor: "trackingNumber",
            title: "Tracking Number",
            width: "4rem",
            Cell: ({ cell: { value } }) => <Link id='trackingNumber' style={{ cursor: 'pointer', iconStyle }} sx={{ textDecoration: 'none' }} onClick={() => trackPackagestatus(value)}> {value}</Link > || '--',
            render: (item) => item?.trackingNumber || '--'
        },
        {
            accessor: "consignee",
            title: "Consignee Name",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.consignee || '--'
        },
        {
            accessor: "addressOne",
            title: "Address Line 1",
            width: "6rem",
            Cell: ({ cell: { row: { original } } }) => original?.addressOne || '--',
            render: (item) => item?.addressOne || '--'
        },
        {
            accessor: "addressTwo",
            title: "Address Line 2",
            width: "6rem",
            Cell: ({ cell: { row: { original } } }) => original?.addressTwo || '--',
            render: (item) => item?.addressTwo || '--'
        },
        {
            accessor: "postalCode",
            title: "Postal Code",
            width: "4rem",
            Cell: ({ cell: { row: { original } } }) => original?.postalCode || '--',
            render: (item) => item?.postalCode || '--'
        },
        {
            accessor: "city",
            title: "City",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.city || '--'
        },
        {
            accessor: "province",
            title: "Province",
            width: "6rem",
            Cell: ({ cell: { row: { original } } }) => original?.province || '--',
            render: (item) => item?.province || '--'
        },
        {
            accessor: "addressGeocode",
            title: "Address Geocode",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.addressGeocode || '--'
        },
        {
            accessor: "serviceAttemptGeoCode",
            title: "Service Attempt Geocode",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.serviceAttemptGeoCode || '--'
        },
        {
            accessor: "serviceAttemptDelta",
            title: "Service Attempt Delta",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.serviceAttemptDelta || '--'
        },
        {
            accessor: "estimatedArrival",
            title: "ETA",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.estimatedArrival || '--'
        },
        {
            accessor: "actualArrivalTime",
            title: "AT",
            width: "6rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.actualArrivalTime || '--'
        },
        {
            accessor: "clientName",
            title: "Client Name",
            width: "3rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.clientName || '--'
        },
        {
            accessor: "specialInstructions",
            title: "Special Instructions",
            width: "6rem",
            Cell: ({ cell: { row: { original } } }) => <SpecialInstrucionsCellRenderer data={original} allowEdit={true} type={'specialInstructions'} />,
            render: (item) => item?.specialInstructions || '--'
        },
        hasInternalCommentsAccess && {
            accessor: "internalComment",
            title: "Internal Comments",
            width: "5rem",
            Cell: ({ cell: { row: { original } } }) => <SpecialInstrucionsCellRenderer data={original} allowEdit={true} type={'internalComments'} />,
            render: (item) => item?.internalComment || '--'
        },
        {
            accessor: 'attribute',
            title: "Attributes",
            width: "5rem",
            Cell: ({ cell: { row: { original } } }) => <DeliveryStatusCellRenderer key={original?.id} isHighPriority={original?.highPriority} isSLA={original?.sla} isHighValueItem={original?.highValueItem} allowEditChip={false} parcel={original} />,
            render: (item) => getDeliveryStatusValue(item?.highPriority, item?.sla, item?.highValueItem)
        }
    ]?.filter(Boolean)

    return (
        <>
            <div className='content'>
                <DataTable columns={columns} data={data} uniqueKey={rowKey}
                    rowKey={rowKey} maxHeight={height}
                />
            </div>
            <Audit />
        </>
    )
}